import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar
} from 'recharts';
import './ManagerView.css';

function ManagerView() {
  const navigate = useNavigate(); // Initialize navigate for page navigation
  const [metrics, setMetrics] = useState(null);
  const [dailyRevenue, setDailyRevenue] = useState({});
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showRevenue, setShowRevenue] = useState(true); // Default to show revenue

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [metricsResponse, revenueResponse] = await Promise.all([
          fetch('https://project-3-team3p.onrender.com/api/dashboard/metrics'),
          fetch('https://project-3-team3p.onrender.com/api/dashboard/daily-revenue')
        ]);

        const metricsData = await metricsResponse.json();
        const revenueData = await revenueResponse.json();

        if (metricsData.totalRevenue) {
          metricsData.totalRevenue = parseFloat(metricsData.totalRevenue);
        }

        setMetrics(metricsData);
        setDailyRevenue(revenueData);
      } catch (err) {
        setError('Failed to fetch dashboard data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchInventoryData = async () => {
    try {
      const response = await fetch('https://project-3-team3p.onrender.com/api/dashboard/inventory');
      const inventoryData = await response.json();
      setInventoryData(inventoryData);
    } catch (err) {
      setError('Failed to fetch inventory data');
      console.error(err);
    }
  };

  const filterChartData = () => {
    return inventoryData.map(item => ({
      item_name: item.item_name,
      quantity: item.quantity
    }));
  };

  useEffect(() => {
    fetchInventoryData(); // Fetch inventory data when component mounts
  }, []);

  if (loading) return <div>Loading dashboard...</div>;
  if (error) return <div className="error">{error}</div>;

  // Sort daily revenue by date
  const sortedDailyRevenue = Object.entries(dailyRevenue)
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .reduce((obj, [key, value]) => ({
      ...obj,
      [key]: value
    }), {});

  // Transform the data for recharts
  const chartData = Object.entries(sortedDailyRevenue).map(([date, revenue]) => ({
    date,
    revenue
  }));

  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  const formatCurrency = (value) => {
    return `$${value.toFixed(2)}`;
  };

  const handleToggleCharts = () => {
    setShowRevenue(prev => !prev);
  };

  const goToRestockPage = () => {
    navigate('/restock'); // Navigate to restock page using navigate
  };

  return (
    <div className="manager-dashboard">
      <h1>Manager Dashboard</h1>
      
      <div className="metrics-grid">
        <div className="metric-card">
          <h3>Total Revenue (30 days)</h3>
          <p>${metrics?.totalRevenue ? metrics.totalRevenue.toFixed(2) : '0.00'}</p>
        </div>
        <div className="metric-card">
          <h3>Total Orders</h3>
          <p>{metrics?.orderCount || 0}</p>
        </div>
        <div className="metric-card">
          <h3>Average Order Value</h3>
          <p>${metrics?.orderCount ? (metrics.totalRevenue / metrics.orderCount).toFixed(2) : '0.00'}</p>
        </div>
      </div>

      <div className="top-items">
        <h2>Top Selling Items</h2>
        <ol className="items-grid">
          {metrics?.topItems?.map((item, index) => (
            <div key={item.name} className="item-card">
              <h3>{`${index + 1}. ${item.name}`}</h3>
              <p>Sold: {item.count}</p>
              <p>Revenue: ${parseFloat(item.revenue).toFixed(2)}</p>
            </div>
          ))}
        </ol>
      </div>

      <h2 className="chart-title">Manager Charts</h2>
      <div className="chart-toggle">
        <span className="switch-label left">Show Inventory Data</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={showRevenue}
            onChange={handleToggleCharts}
          />
          <span className="slider"></span>
        </label>
        <span className="switch-label right">Show Revenue</span>
      </div>

      {showRevenue ? (
        <div className="revenue-chart">
          <h2>Daily Revenue (Last 30 Days)</h2>
          <div style={{ height: '500px', width: '100%' }}> {/* Increased height */}
            <ResponsiveContainer>
              <LineChart
                data={chartData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 20,
                  bottom: 40 // Increased bottom margin
                }}
                style={{ background: 'none' }} // Remove the background
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  tick={{ fontSize: 12 }}
                />
                <YAxis
                  tickFormatter={formatCurrency}
                  tick={{ fontSize: 12 }}
                  width={80}
                />
                <Tooltip
                  formatter={(value) => [`${formatCurrency(value)}`, 'Revenue']}
                  labelFormatter={formatDate}
                  contentStyle={{
                    backgroundColor: 'white',
                    border: '1px solid #f0f0f0',
                    borderRadius: '4px',
                    padding: '8px'
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="revenue"
                  stroke="#d02030"
                  strokeWidth={2}
                  dot={{
                    fill: '#d02030',
                    r: 4
                  }}
                  activeDot={{
                    r: 6,
                    fill: '#d02030',
                    stroke: 'white',
                    strokeWidth: 2
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="inventory-histogram">
          <h3>Total Inventory Histogram</h3>
          <div style={{ height: '500px', width: '100%' }}> {/* Increased height */}
            <ResponsiveContainer>
              <BarChart
                data={filterChartData()}
                margin={{
                  top: 10,
                  right: 30,
                  left: 20,
                  bottom: 100 // Increased bottom margin
                }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis
                  dataKey="item_name"
                  angle={-45}
                  textAnchor="end"
                  tick={{ fontSize: 14 }} // Adjusted font size here
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="quantity" fill="#d1282e" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}

      {/* Button to navigate to Restock Page */}
      <div className="restock-button-container">
        <button className="restock-button" onClick={goToRestockPage}>Go to Restock Page</button>
      </div>
    </div>
  );
}

export default ManagerView;