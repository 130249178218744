import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.css';
import Navbar from './components/Navbar';
import CustomerView from './components/CustomerView';
import CashierView from './components/CashierView';
import ManagerView from './components/ManagerView';
import Home from './components/Home';
import { OrderProvider } from './OrderContext';
import Weather from './components/Weather';
import LanguageSelector from './components/LanguageSelector';
import RestockPage from './components/RestockPage';

function App() {
  const handleLanguageChange = (langCode) => {
    document.documentElement.lang = langCode;
    localStorage.setItem('selectedLanguage', langCode);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      document.documentElement.lang = savedLanguage;
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId="AIzaSyB3PFTfiJE6LqGpOxrxDbs9vMZYf05tVEo">
      <Router>
        <div className="App">
          <Navbar />
          <div className="utility-bar">
            <Weather />
            <LanguageSelector onLanguageChange={handleLanguageChange} />
          </div>
          <div className="main-content">
            <OrderProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/customer" element={<CustomerView />} />
                <Route path="/manager" element={<ManagerView />} />
                <Route path="/cashier" element={<CashierView />} />
                <Route path="/restock" element={<RestockPage />} />
              </Routes>
            </OrderProvider>
          </div>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;