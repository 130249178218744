// OrderContext.js
import React, { createContext, useState, useContext } from 'react';

const OrderContext = createContext();

export const useOrder = () => useContext(OrderContext);

export const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState([]);

  const addItemToOrder = (item) => {
    setOrder((prevOrder) => {
      const existingItemIndex = prevOrder.findIndex(
        (orderItem) => orderItem.name === item.name && !orderItem.type // Ensures single items are updated, not duplicated
      );

      if (existingItemIndex !== -1) {
        const updatedOrder = [...prevOrder];
        updatedOrder[existingItemIndex].quantity += item.quantity || 1;
        return updatedOrder;
      } else {
        return [...prevOrder, { ...item, quantity: item.quantity || 1 }];
      }
    });
  };

  const updateItemQuantity = (index, change) => {
    setOrder((prevOrder) => {
      const updatedOrder = [...prevOrder];
      const item = updatedOrder[index];
      const newQuantity = item.quantity + change;

      if (newQuantity > 0) {
        updatedOrder[index] = { ...item, quantity: newQuantity };
      } else {
        updatedOrder.splice(index, 1); // Remove the item if quantity goes to zero
      }

      return updatedOrder;
    });
  };

  const removeItemFromOrder = (itemIndex) => {
    setOrder((prevOrder) => prevOrder.filter((_, index) => index !== itemIndex));
  };

  const clearOrder = () => {
    setOrder([]);
  };

  const totalOrderPrice = order.reduce((sum, item) => sum + item.price * item.quantity, 0);

  return (
    <OrderContext.Provider value={{ order, addItemToOrder, updateItemQuantity, removeItemFromOrder, clearOrder, totalOrderPrice }}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
