import React, { useState, useEffect } from 'react';
import './CustomerView.css';

function CustomerView() {
  const [cart, setCart] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Entree'); // New state for category selection
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderVisible, setOrderVisible] = useState(false);

  useEffect(() => {
    fetch('https://project-3-team3p.onrender.com/api/menu')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched menu items:', data);
        setMenuItems(data);
      })
      .catch(error => {
        console.error('Error fetching menu items:', error);
        setError('Failed to fetch menu items. Is the backend server running?');
      });
  }, []);

  useEffect(() => {
    setOrderVisible(cart.length > 0);
  }, [cart]);

  const addToCart = (item) => {
    setCart([...cart, item]);
  };

  const removeFromCart = (index) => {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
  };

  const clearCart = () => {
    setCart([]);
  };

  const total = cart.reduce((sum, item) => sum + parseFloat(item.price), 0);

  const handleCheckout = async () => {
    try {
      const response = await fetch('https://project-3-team3p.onrender.com/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: cart,
          total_amount: total
        }),
      });

      const result = await response.json();
      if (result.status === 'success') {
        setShowConfirmation(true);
        clearCart();
        setTimeout(() => {
          setShowConfirmation(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error creating order:', error);
      setError('Failed to complete checkout. Please try again.');
    }
  };

  // Filter menu items based on selected category
  const filteredMenuItems = menuItems.filter(item => item.category === selectedCategory);

  const MenuItemCard = ({ item, onAddToCart }) => {
    const [showInfo, setShowInfo] = useState(false);

    const formatAllergens = (allergens) => {
      if (!allergens || allergens.length === 0) return 'None';
      if (typeof allergens === 'string') {
        // Handle string format from database
        return allergens.replace(/[{}"]/g, '').split(',').join(', ');
      }
      if (Array.isArray(allergens)) {
        return allergens.join(', ');
      }
      return 'None';
    };

    return (
      <div className="menu-item">
        <button className="info-button" onClick={(e) => {
          e.stopPropagation();
          setShowInfo(true);
        }}>i</button>
        <img src={item.image_url} alt={item.name} />
        <h3>{item.name}</h3>
        <p className="price">${parseFloat(item.price).toFixed(2)}</p>
        <button onClick={() => onAddToCart(item)}>Add to Order</button>

        {showInfo && (
          <>
            <div className="modal-backdrop" onClick={() => setShowInfo(false)} />
            <div className="info-modal">
              <button className="close-modal" onClick={() => setShowInfo(false)}>&times;</button>
              <h3>{item.name}</h3>
              <p><strong>Description:</strong> {item.description}</p>
              <p><strong>Calories:</strong> {item.calories}</p>
              <p><strong>Allergens:</strong> {formatAllergens(item.allergens)}</p>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="customer-view">
      <h1>Menu</h1>
      <nav className="menu-nav">
        <button 
          className={selectedCategory === 'All' ? 'active' : ''} 
          onClick={() => setSelectedCategory('All')}
        >
          All Items
        </button>
        <button 
          className={selectedCategory === 'Entree' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Entree')}
        >
          Entree
        </button>
        <button 
          className={selectedCategory === 'Appetizer' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Appetizer')}
        >
          Appetizer
        </button>
        <button 
          className={selectedCategory === 'Drink' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Drink')}
        >
          Drink
        </button>
        <button 
          className={selectedCategory === 'Side' ? 'active' : ''} 
          onClick={() => setSelectedCategory('Side')}
        >
          Side
        </button>
      </nav>
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="content">
          <div className="menu">
            <div className="menu-items">
              {menuItems
                .filter(item => selectedCategory === 'All' || item.category === selectedCategory)
                .map(item => (
                  <MenuItemCard key={item.id} item={item} onAddToCart={addToCart} />
                ))}
            </div>
          </div>
          
          <div className={`order-summary ${orderVisible ? 'visible' : ''}`}>
            <h2>Your Order</h2>
            {cart.length === 0 ? (
              <p>Your order is empty</p>
            ) : (
              <>
                <ul>
                  {cart.map((item, index) => (
                    <li key={index}>
                      {item.name} - ${parseFloat(item.price).toFixed(2)}
                      <button onClick={() => removeFromCart(index)}>Remove</button>
                    </li>
                  ))}
                </ul>
                <p className="total">Total: ${total.toFixed(2)}</p>
                <button className="clear-cart" onClick={clearCart}>Clear Order</button>
                <button className="checkout" onClick={handleCheckout}>Checkout</button>
              </>
            )}
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="order-confirmation">
          <h2>Order Placed Successfully!</h2>
          <p>Thank you for your order.</p>
          <button onClick={() => setShowConfirmation(false)}>Continue Shopping</button>
        </div>
      )}
    </div>
  );
}

export default CustomerView;