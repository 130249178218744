import React, { useState, useEffect } from 'react';
import './RestockPage.css';

function RestockPage() {
  const [inventoryData, setInventoryData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [restockingItem, setRestockingItem] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [restockQuantity, setRestockQuantity] = useState('');
  const [newItemDetails, setNewItemDetails] = useState({
    name: '',
    price: '',
    category: '',
    description: '',
    calories: '',
    allergens: '',
  });

  // Fetch inventory data and categories on component mount
  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await fetch('https://project-3-team3p.onrender.com/api/dashboard/inventory');
        const data = await response.json();
        setInventoryData(data);
      } catch (error) {
        console.error('Error fetching inventory data:', error);
      }
    };
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://project-3-team3p.onrender.com/api/dashboard/categories');
        const categories = await response.json();
        setCategoryOptions(categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchInventory();
    fetchCategories();
  }, []);

  const handleAddNewItem = async () => {
    try {
      const response = await fetch('https://project-3-team3p.onrender.com/api/dashboard/add-item', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newItemDetails),
      });

      if (response.ok) {
        const addedItem = await response.json();
        setInventoryData((prevData) => [...prevData, addedItem]);
        setShowAddForm(false);
        setNewItemDetails({
          name: '',
          price: '',
          category: '',
          description: '',
          calories: '',
          allergens: '',
        });
      } else {
        console.error('Failed to add new item');
      }
    } catch (error) {
      console.error('Error adding new item:', error);
    }
  };

  const handleDeleteItem = async (name) => {
    try {
      const response = await fetch(`https://project-3-team3p.onrender.com/api/dashboard/delete-item/${name}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        setInventoryData((prevData) => prevData.filter((item) => item.item_name !== name));
      } else {
        console.error('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };
  

  const handleEdit = (item) => {
    setEditingItem(item);
    setRestockingItem(null);
    setNewItemDetails({ name: item.item_name, price: item.price });
  };

  const handleRestock = (item) => {
    setRestockingItem(item);
    setEditingItem(null);
    setRestockQuantity('');
  };

  const handleSaveRestock = async () => {
    const updatedQuantity = parseInt(restockQuantity, 10);
    if (isNaN(updatedQuantity) || updatedQuantity <= 0) {
      console.error('Invalid quantity');
      return;
    }

    try {
      const response = await fetch(`https://project-3-team3p.onrender.com/api/dashboard/restock/${restockingItem.id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ quantity: updatedQuantity }),
      });

      if (response.ok) {
        const updatedItem = await response.json();
        setInventoryData((prevData) =>
          prevData.map((i) => (i.id === updatedItem.id ? updatedItem : i))
        );
        setRestockingItem(null);
      } else {
        console.error('Failed to restock item');
      }
    } catch (error) {
      console.error('Error updating inventory quantity:', error);
    }
  };

  const handleSaveEdit = async () => {
    if (!newItemDetails.name || !newItemDetails.price) {
      console.error('Item name and price are required');
      return;
    }

    try {
      const response = await fetch(`https://project-3-team3p.onrender.com/api/dashboard/edit/${editingItem.id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: newItemDetails.name,
          price: parseFloat(newItemDetails.price),
        }),
      });

      if (response.ok) {
        const updatedItem = await response.json();
        setInventoryData((prevData) =>
          prevData.map((item) =>
            item.id === updatedItem.id
              ? { ...item, item_name: updatedItem.name, price: updatedItem.price }
              : item
          )
        );
        setEditingItem(null);
      } else {
        console.error('Failed to update item');
      }
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleCancelRestock = () => setRestockingItem(null);
  const handleCancelEdit = () => setEditingItem(null);

  const sortedItems = inventoryData.sort((a, b) => a.item_name.localeCompare(b.item_name));

  return (
    <div className="restock-page">
      <h1>Restock Page</h1>
      <button onClick={() => setShowAddForm((prev) => !prev)}>
        {showAddForm ? 'Cancel New Item' : 'Add New Item'}
      </button>

      {showAddForm && (
        <div className="add-item-form">
          <input
            type="text"
            placeholder="Name"
            value={newItemDetails.name}
            onChange={(e) => setNewItemDetails({ ...newItemDetails, name: e.target.value })}
          />
          <input
            type="number"
            placeholder="Price"
            value={newItemDetails.price}
            onChange={(e) => setNewItemDetails({ ...newItemDetails, price: e.target.value })}
          />
          <select
            value={newItemDetails.category}
            onChange={(e) => setNewItemDetails({ ...newItemDetails, category: e.target.value })}
          >
            <option value="">Select Category</option>
            {categoryOptions.map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Description"
            value={newItemDetails.description}
            onChange={(e) => setNewItemDetails({ ...newItemDetails, description: e.target.value })}
          />
          <input
            type="number"
            placeholder="Calories"
            value={newItemDetails.calories}
            onChange={(e) => setNewItemDetails({ ...newItemDetails, calories: e.target.value })}
          />
          <input
            type="text"
            placeholder="Allergens (comma-separated)"
            value={newItemDetails.allergens}
            onChange={(e) => setNewItemDetails({ ...newItemDetails, allergens: e.target.value })}
          />
          <button onClick={handleAddNewItem}>Save New Item</button>
          
        </div>
      )}

      <div className="inventory-list">
        {sortedItems.map((item) => (
          <div key={item.id} className="inventory-item">
            <div className="item-info">
              <span className="item-name">{item.item_name}: </span>
              <span className="item-quantity">Qty-{item.quantity} </span>
              <span className="item-restock">Restock Level-{item.reorder_level}</span>
            </div>
            <div className="item-actions">
              <button onClick={() => handleEdit(item)}>Edit</button>
              <button onClick={() => handleRestock(item)}>Restock</button>
              <button onClick={() => handleDeleteItem(item.item_name)}>Delete</button>
            </div>

            {restockingItem && restockingItem.id === item.id && (
              <div className="restock-form">
                <input
                  type="number"
                  value={restockQuantity}
                  onChange={(e) => setRestockQuantity(e.target.value)}
                  placeholder="Enter quantity to restock"
                />
                <button onClick={handleSaveRestock}>Save Restock</button>
                <button onClick={handleCancelRestock}>Cancel</button>
              </div>
            )}

            {editingItem && editingItem.id === item.id && (
              <div className="edit-form">
                <input
                  type="text"
                  value={newItemDetails.name}
                  onChange={(e) => setNewItemDetails({ ...newItemDetails, name: e.target.value })}
                  placeholder="New item name"
                />
                <input
                  type="number"
                  value={newItemDetails.price}
                  onChange={(e) => setNewItemDetails({ ...newItemDetails, price: e.target.value })}
                  placeholder="New item price"
                />
                <button onClick={handleSaveEdit}>Save</button>
                <button onClick={handleCancelEdit}>Cancel</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default RestockPage;
