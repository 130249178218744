import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import GoogleSignIn from './GoogleSignIn';

function Home() {
  return (
    <div className="home">
      <div className="header">
        <h1 data-translate="true">Panda Express POS</h1>
        <img src="/assets/Panda_Express.svg" alt="Logo" className="logo-image" />
      </div>
      <GoogleSignIn />
      <div className="home-links">
        <Link to="/customer" className="home-link">Order Now</Link>
        <Link to="/cashier" className="home-link">Cashier</Link>
        <Link to="/manager" className="home-link">Manager</Link>
      </div>
    </div>
  );
}

export default Home;
