import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './LanguageSelector.css';
import { useTranslation } from '../hooks/useTranslation';

function LanguageSelector({ onLanguageChange }) {
  const { translate, loading } = useTranslation('AIzaSyB3PFTfiJE6LqGpOxrxDbs9vMZYf05tVEo');
  const location = useLocation();

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'zh', name: '中文' },
    { code: 'vi', name: 'Tiếng Việt' },
    { code: 'tl', name: 'Tagalog' },
    { code: 'fr', name: 'Français' }
  ];

  // Apply translations when component mounts
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage && savedLanguage !== 'en') {
      handleLanguageSelect(savedLanguage);
    }
  }, []);

  // Re-apply translations when route changes
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage && savedLanguage !== 'en') {
      // Small delay to ensure new route content is rendered
      setTimeout(() => {
        handleLanguageSelect(savedLanguage);
      }, 100);
    }
  }, [location.pathname]);

  const handleLanguageSelect = async (langCode) => {
    if (loading) return;
    
    try {
      localStorage.setItem('selectedLanguage', langCode);
      const elements = document.querySelectorAll('h1, h2, h3, h4, h5, h6, p, span, button, a, label, option');
      for (const element of elements) {
        // Skip elements that are part of the language selector itself
        if (element.closest('.language-selector')) continue;
        
        const originalText = element.getAttribute('data-original-text') || element.textContent.trim();
        if (!originalText) continue;

        if (langCode === 'en') {
          element.textContent = originalText;
        } else {
          const translatedText = await translate(originalText, langCode);
          element.setAttribute('data-original-text', originalText);
          element.textContent = translatedText;
        }
      }
      onLanguageChange(langCode);
    } catch (error) {
      console.error('Translation failed:', error);
    }
  };

  return (
    <div className="language-selector">
      <select 
        onChange={(e) => handleLanguageSelect(e.target.value)} 
        disabled={loading}
        value={localStorage.getItem('selectedLanguage') || 'en'}
      >
        {languages.map(lang => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
      {loading && <span className="loading-indicator">Translating...</span>}
    </div>
  );
}

export default LanguageSelector; 