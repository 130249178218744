import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src="/assets/Panda_Express.svg" alt="Panda Express" />
        </Link>
        <div className="nav-links">
          <Link to="/customer" className="nav-link">Order Now</Link>
          <Link to="/cashier" className="nav-link">Cashier</Link>
          <Link to="/manager" className="nav-link">Manager</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
