import React, { useState, useEffect } from 'react';
import './Weather.css';

function Weather() {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeather = async (position) => {
      try {
        const { latitude, longitude } = position.coords;
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}&units=imperial`
        );
        
        if (!response.ok) {
          throw new Error('Weather data not available');
        }
        
        const data = await response.json();
        setWeather(data);
        setLoading(false);
      } catch (err) {
        console.error('Weather fetch error:', err);
        setError('Unable to fetch weather data');
        setLoading(false);
      }
    };

    const handleError = (err) => {
      console.error('Geolocation error:', err);
      setError('Unable to get location');
      setLoading(false);
    };

    // Get user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(fetchWeather, handleError);
    } else {
      setError('Geolocation is not supported');
      setLoading(false);
    }
  }, []);

  if (loading) return <div className="weather-widget">Loading weather...</div>;
  if (error) return <div className="weather-widget error">{error}</div>;
  if (!weather || !weather.main) return <div className="weather-widget">Weather unavailable</div>;

  return (
    <div className="weather-widget">
      <div className="weather-temp">{Math.round(weather.main.temp)}°F</div>
      <div className="weather-desc">
        {weather.weather && weather.weather[0] ? weather.weather[0].description : 'No description available'}
      </div>
    </div>
  );
}

export default Weather;