import { GoogleLogin } from '@react-oauth/google';
import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

function GoogleSignIn() {
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    const savedName = localStorage.getItem('userName');
    if (savedName) {
      setUserName(savedName);
    }
  }, []);

  const handleSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    setUserName(decoded.name);
    localStorage.setItem('userName', decoded.name);
  };

  const handleError = () => {
    console.error('Login Failed');
  };

  const handleLogout = () => {
    setUserName(null);
    localStorage.removeItem('userName');
  };

  return (
    <div className="google-signin">
      {!userName ? (
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          useOneTap
          theme="filled_black"
          shape="pill"
          size="large"
          text="signin_with"
          type="standard"
        />
      ) : (
        <div className="user-welcome">
          Welcome, {userName}!
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      )}
    </div>
  );
}

export default GoogleSignIn; 